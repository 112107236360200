import React, { useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import Operator from "./operator";
//import dayjs from "dayjs";
import Calendar from "./../Calendar/Calendar";
//import { TextField } from "@mui/material";
import Tags from "./Tags";
import { fetchOperators } from "../../slices/operaters";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getAllTags } from "./../../slices/tags";
import { useSelector, useDispatch } from "react-redux";
import { findInValues } from "../../utilities/search";
import { useTranslation } from "react-i18next";
const popover = ({
  open,
  anchorEl,
  onClose,
  id,
  setAnchorEl,
  // divRef,
  // activeToDoList,
}) => {
  // const [value, setValue] = useState(dayjs("2014-08-18T21:11:54"));

  const dispatch = useDispatch();
  const { operators, statusOperatorsList } = useSelector(
    (state) => state.operators
  );
  const [active, setActive] = useState(operators[0]);

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const { tags, tagsStatus } = useSelector((state) => state.tags);
  useEffect(() => {
    tags?.length === 0 &&
      user.websiteID &&
      open &&
      dispatch(getAllTags({ websiteID: user.websiteID }));
  }, [open, tags?.length === 0, user.websiteID]);
  useEffect(() => {
    operators?.length === 0 &&
      user.websiteID &&
      open &&
      dispatch(fetchOperators({ websiteID: user.websiteID, query: "" }));
  }, [open, operators?.length === 0, user.websiteID]);
  const [inputText, setInputText] = useState("");
  const inputHandler = (event) => {
    setInputText(event.target.value);
  };
  return (
    <Popover
      open={open}
      id="assigned-to"
      anchorEl={anchorEl}
      style={{ borderRadius: "10px" }}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      BackdropProps={{ invisible: true }}
    >
      <div style={{ width: "358px" }}>
        {(id === "assignedto" || id === "tags") && (
          <div className="popover-header">
            <input
              placeholder={t("select an option")}
              onChange={inputHandler}
              value={inputText}
            />
          </div>
        )}
        {id === "assignedto" && (
          <div className="operators-wrapper">
            {findInValues(operators, inputText)
              ?.filter((operator) => operator?.isVerified === true)
              ?.map((item, index) => {
                return (
                  <Operator
                    active={active}
                    onClose={onClose}
                    item={item}
                    setAnchorEl={setAnchorEl}
                    key={index}
                    statusOperatorsList={statusOperatorsList}
                    setActive={setActive}
                  />
                );
              })}
          </div>
        )}
        {id === "tags" && (
          <Tags
            tags={findInValues(tags, inputText)}
            active={active}
            setActive={setActive}
            tagsStatus={tagsStatus}
            onClose={onClose}
          />
        )}
      </div>
      {id === "duedate" && (
        <div style={{ padding: "10px", width: "342px" }}>
          <div className="calendar-header">{t("Select Your Date")}</div>
          <Calendar setAnchorEl={setAnchorEl} />
          {/* <div className="alert-due-time-wrapper"> */}
          {/* <div className="due-time-close-btn-wrapper"> */}
          {/* <div className="due-time-wrapper">
                <div className="due-time">Due&nbsp;Time</div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TextField
                    id="time"
                    type="time"
                    defaultValue="07:30"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300,
                    }}
                    sx={{ width: 150 }}
                  />
                </LocalizationProvider>
              </div> */}
          {/* <IconButton style={{ marginTop: "5px" }}>
                <CloseIcon />
              </IconButton> */}
          {/* </div> */}
          {/* <div className="due-time-close-btn-wrapper"> */}
          {/* <div className="due-time-wrapper">
                <div className="due-time">Alert&nbsp;Time</div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TextField
                    id="time"
                    type="time"
                    defaultValue="07:30"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    sx={{ width: 150 }}
                  />
                </LocalizationProvider>
              </div> */}
          {/* <IconButton style={{ marginTop: "5px" }}>
                <CloseIcon />
              </IconButton> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="actions-btn-wrapper">
            <button className="cancel-btn"> Confirm</button>
            <button className="confirm-btn">Cancel </button>
          </div>*/}
        </div>
      )}
    </Popover>
  );
};

export default popover;
