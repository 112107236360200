import { CircularProgress } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { getTags } from "../../slices/todolist";
import { ColorLuminance } from "./../../utilities/randomColor";
const Tags = ({ setActive, tags, onClose, tagsStatus }) => {
  const dispatch = useDispatch();
  return (
    <div className="tags-wrapper">
      {tagsStatus === "loading" ? (
        <CircularProgress style={{ color: "white" }} />
      ) : tags.length === 0 ? (
        <span style={{ color: "red", padding: "10px", margin: "auto" }}>
          {" "}
          Tag Not found
        </span>
      ) : (
        tags?.map((item) => {
          return (
            <div
              className="tag-item-wrapper"
              key={item._id}
              onClick={() => {
                setActive(item.name);
                dispatch(getTags({ id: item._id, name: item.name }));
                onClose();
              }}
            >
              <div
                className="tag-item"
                style={{ backgroundColor: ColorLuminance() }}
                key={item._id}
              >
                {item.name}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Tags;
