import React from "react";
import { getOperator } from "./../../slices/todolist";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Operator = React.memo(
  ({ statusOperatorsList, setActive, item, setAnchorEl }) => {
    const dispatch = useDispatch();
    return (
      <>
        {statusOperatorsList === "loading" ? (
          <CircularProgress style={{ color: "white" }} />
        ) : (
          <div
            className="operator-img-wrapper"
            onClick={() => {
              setActive(item);
              setAnchorEl(null);
              dispatch(getOperator(item));
            }}
          >
            <LazyLoadImage src={item.avatar} id="avatar-img" />
            <span>
              {item.firstName}&nbsp;{item.lastName}
            </span>
          </div>
        )}
      </>
    );
  }
);

export default Operator;
